<template>
    <div class="relative isolate pt-14 2xl:pt-32 h-full">
        <div class="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-2xl sm:top-[calc(100%-40rem)]" aria-hidden="true">
            <div class="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ffcccb] to-[red] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)" />
        </div>
        <div class="mx-auto max-w-7xl px-6 pt-20 sm:py-16 lg:flex lg:items-center lg:gap-x-10 lg:px-8 3xl:py-32 h-3/4">
            <div class="mx-auto max-w-2xl lg:mx-0 lg:flex-auto" data-aos="fade-up" data-aos-delay="200">
                <div class="flex">
                    <div class="relative flex items-center gap-x-4 rounded-full px-4 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                        <span class="font-semibold text-red-600">{{ $t('hero.hiring') }}</span>
                        <span class="h-4 w-px bg-gray-900/10" aria-hidden="true" />
                        <NuxtLink :to="localePath('/careers')" class="flex items-center gap-x-1">
                            <span class="absolute inset-0" aria-hidden="true" />
                            {{ $t('hero.open-positions') }}
                            <ChevronRightIcon class="-mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                        </NuxtLink>
                    </div>
                </div>
              <h1 class="mt-10 max-w-2xl text-2xl font-bold tracking-tight text-gray-900 2xl:text-4xl">{{ $t('hero.title') }}</h1>
              <h1 class="mt-4 max-w-2xl text-xl font-bold tracking-tight text-gray-900 2xl:text-3xl">{{ $t('hero.title2') }}</h1>
                <p class="mt-6 text-lg leading-8 text-gray-600">{{ $t('hero.subtitle') }}</p>
                <div class="mt-10 flex items-center gap-x-6">
                    <NuxtLink :to="localePath('/about-us')" class="rounded-md bg-red-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600">{{ $t('hero.get-started') }}</NuxtLink>
                    <a :href="'/pdf/' + $t('hero.pdf-name')" :download="$t('hero.pdf-name')" class="text-sm font-semibold leading-6 text-gray-900">{{ $t('hero.learn-more') }}<span aria-hidden="true"> →</span></a>
                </div>
            </div>
            <div class="mt-8 sm:mt-24 lg:mt-0 lg:flex-shrink-0 lg:flex-grow" data-aos="fade-left" data-aos-delay="300">
                <ThreejsPlanet />
            </div>
        </div>
        <!-- Logo cloud -->
        <div class="mx-auto mt-4 max-w-7xl px-6 sm:mt-16 lg:px-8 hidden sm:block">
            <div class="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-3 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                <div class="col-span-2 max-h-12 w-full object-contain lg:col-span-1 grayscale hover:grayscale-0" data-aos="zoom-in-up" data-aos-delay="500">
                    <div class="ease-in duration-200 hover:scale-125">
                        <UtilsLogoEME />
                    </div>
                </div>
                <div class="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1 grayscale hover:grayscale-0 ease-in-out duration-300 hover:scale-125" data-aos="zoom-in-up" data-aos-delay="750">
                    <div class="ease-in duration-200 hover:scale-125">
                        <UtilsLogoMaltem />
                    </div>
                </div>
                <div class="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1 grayscale hover:grayscale-0 ease-in-out duration-300 hover:scale-125" data-aos="zoom-in-up" data-aos-delay="1000">
                    <div class="ease-in duration-200 hover:scale-125">
                        <UtilsLogoMonacoCloud />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import {
    ChevronRightIcon
} from '@heroicons/vue/20/solid'
</script>
