<template>
    <div>
        <div style="height: 100vh">
            <Hero />
        </div>
        <Banner />
        <Services />
        <Footer />
    </div>
</template>

